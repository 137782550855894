const Highlight = ({data}) => {
    return (
        <div className="highlight mb-5">
            {data.map((d) => {
                return <div dangerouslySetInnerHTML={{__html: d}} />
            })}
        </div>
    )
}

export default Highlight