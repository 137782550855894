import './App.css'
import axios from "axios"
import {useState, useEffect, useCallback} from "react"
import { useSearchParams } from 'react-router-dom'
import Highlight from './components/highlight'
import ProgressBar from '@badrap/bar-of-progress'

const progress = new ProgressBar({
  size: 3,
})

const App = () => {
  const [result, setResult] = useState([])
  const [total, setTotal] = useState(0)
  const [query, setQuery] = useState('')
  const [from, setFrom] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [firstLoaded, setFirstLoaded] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const search = useCallback(() => {
    progress.start()
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    axios
      // .get('http://localhost:8080/api/v1/book/', {
      .get('https://api.flbst.net/api/v1/book/', {
        params: searchParams
      })
      .then((response) => {
        setResult(response.data.hits.hits)
        setTotal(response.data.hits.total.value)
      })
      .catch(function (error) {
        setResult([])
        alert(error.response.data.error);
      })
      .then((response) => {
        setFirstLoaded(true)
        progress.finish()
      });
  }, [searchParams])

  useEffect(() => {
    if( searchParams.toString() === '' ){
      return
    }

    setQuery(searchParams.get('q'))
    setPerPage(parseInt(searchParams.get('perPage')))
    setFrom(parseInt(searchParams.get('from')))

    search()
  }, [searchParams, search])

  const perPageList = [10, 20, 50, 70, 100]

  var searchHandler = (e) => {
    e.preventDefault()

    searchParams.set("q", query)
    searchParams.set("from", 0)
    searchParams.set("perPage", perPage)
    setSearchParams(searchParams)
  }

  var perPageHandler = (e) => {
    searchParams.set("perPage", e.target.value)
    searchParams.set("from", 0)
    setSearchParams(searchParams)
  }

  var fromHandler = (next) => {
    var v = from

    if (next) {
      v += perPage
    } else {
      v -= perPage
    }

    searchParams.set("from", v)
    setSearchParams(searchParams)
  }

  return (
    <div className='px-5'>
      <header className="container mx-auto py-10">
        <div className="relative rounded-lg bg-white shadow-md mb-2">
         <form onSubmit={searchHandler}>
            <input
              onChange={e => setQuery(e.target.value)}
              className="block w-full appearance-none bg-transparent py-4 pl-4 pr-12 text-base text-slate-900 placeholder:text-slate-600 focus:outline-none sm:text-sm sm:leading-6"  
              placeholder='Find your book'
              value={query}
              type="text" 
            />
            <button type='submit' className='absolute top-4 right-4'>
              <svg className="pointer-events-none h-6 w-6 fill-slate-400" xmlns="http://www.w3.org/2000/svg"><path d="M20.47 21.53a.75.75 0 1 0 1.06-1.06l-1.06 1.06Zm-9.97-4.28a6.75 6.75 0 0 1-6.75-6.75h-1.5a8.25 8.25 0 0 0 8.25 8.25v-1.5ZM3.75 10.5a6.75 6.75 0 0 1 6.75-6.75v-1.5a8.25 8.25 0 0 0-8.25 8.25h1.5Zm6.75-6.75a6.75 6.75 0 0 1 6.75 6.75h1.5a8.25 8.25 0 0 0-8.25-8.25v1.5Zm11.03 16.72-5.196-5.197-1.061 1.06 5.197 5.197 1.06-1.06Zm-4.28-9.97c0 1.864-.755 3.55-1.977 4.773l1.06 1.06A8.226 8.226 0 0 0 18.75 10.5h-1.5Zm-1.977 4.773A6.727 6.727 0 0 1 10.5 17.25v1.5a8.226 8.226 0 0 0 5.834-2.416l-1.061-1.061Z"></path></svg>
            </button>
          </form>
        </div>
        <div className='text-sm2 text-gray-500 italic'>To narrow your search, put the phrases separated by commas</div>
      </header>
      
      <section className='container mx-auto py-5 mb-10'>
        {result.length > 0 ?
          <>
            <div className='text-xl text-slate-700 mb-5'>Total Results: {total}</div>
            <ul className='grid gap-5 grid-cols-1 mb-10'>
              {result.map((r) => {
                return (
                  <li key={r._id} className='rounded-lg bg-white shadow-md p-5'>
                    <div className='text-xl mb-3'>{r['_source'].title}</div>
                    {r['_source'].author && r['_source'].author.length > 0 && 
                      <em className='mb-2 block text-sm text-stone-500'>Author: {r['_source'].author[0].LastName} {r['_source'].author[0].FirstName}</em>
                    }
                    <Highlight data={r.highlight.content} />
                    <div className='text-stone-500'>
                      Find on: <a className='text-blue-400 hover:underline' href={`http://flibusta.is/booksearch?ask=${r['_source'].title}`} target='_blank'>flibusta.is</a> | <a className='text-blue-400 hover:underline' href={`https://www.google.com/search?q=${r['_source'].title} fb2`} target='_blank'>Google</a>
                    </div>
                  </li>
                )
              })}
            </ul>
            
            <div className='flex justify-between'>
              <div className="flex flex-col">
                <span className="text-sm text-gray-700 mb-2">
                    Showing <span className="font-semibold text-gray-900">{from + 1}</span> to <span className="font-semibold text-gray-900">{(from + perPage) >= total ? total : from + perPage}</span> of <span className="font-semibold text-gray-900">{total}</span> Results
                </span>
                <div className="inline-flex">
                  <button 
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-black rounded-l border-gray-300 border disabled:bg-gray-100"
                    disabled={from === 0}
                    onClick={() => {fromHandler(false)}}
                  >
                      <svg aria-hidden="true" className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path></svg>
                      Prev
                  </button>
                  <button 
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-black rounded-r border-gray-300 border border-l-0 disabled:bg-gray-100"
                    disabled={(from + perPage) >= total}
                    onClick={() => {fromHandler(true)}}
                  >
                      Next
                      <svg aria-hidden="true" className="w-5 h-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  </button>
                </div>
              </div>
              <div>
                <label htmlFor="per_page" className="block mb-2 text-sm text-gray-700">Show per page</label>
                <select 
                  defaultValue={perPage} 
                  id="per_page" 
                  className="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  onChange={perPageHandler}
                >
                  {perPageList.map((v) => {
                    return <option key={v} value={v}>{v}</option>
                  })}
                </select>
              </div>
            </div>

          </>
          :
          <div className={`text-xl text-slate-700 mb-5 ${!firstLoaded && 'hidden'}`}>Total Results: {total}</div>
        }
      </section>
    </div>
  );
}

export default App;
